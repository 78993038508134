import "../styles/navbar.css";

import aclesia_logo from "../images/aclesia-logo.png";
import create_icon from "../images/create.svg";
import axios from "axios";
import { API_BASE_URL } from "../components/config.js";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { encryptText, decryptText } from "../components/crypto.js";

export default function Navbar() {
    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const username = storedProfile?.username;
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            navigate("/login"); // Redirect to login if no token is found
        } else {
            fetchProfile();
        }
    }, [username]); // Dependency on username to refetch if it changes

    const fetchProfile = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}profile/${storedProfile.id}`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setProfile(response.data);
        } catch (error) {
            console.error("Failed to fetch profile", error);
            // Handle errors appropriately
            if (error.response && error.response.status === 404) {
                navigate("/not-found"); // Redirect to a not-found page or display a message
            }
        }
    };
    if (!profile) {
        return <div className="loading-screen"></div>;
    }

    const url_tag = profile.user_type === "user" ? "user" : "church";
    return (
        <>
            <header className="nav-header">
                <div className="nav-wrapper">
                    <a className="nav-image" href="/">
                        <img className="aclesia-logo" src={aclesia_logo} />
                    </a>
                    <nav>
                        <ul className="nav-list">{/* Other nav items */}</ul>
                    </nav>
                    <div className="nav-icons">
                        <a href="/create">
                            <img id="create-icon" src={create_icon} />
                        </a>
                        <a href={`/${url_tag}/${username}`}>
                            <img
                                width="30"
                                height="30"
                                id="profile-pic-img"
                                src={`${
                                    profile.profile_img.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile.profile_img
                                }`}
                            />
                        </a>
                    </div>
                </div>
            </header>
        </>
    );
}
