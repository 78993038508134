import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import LeftBar from "../components/leftbar";
import { API_BASE_URL } from "../components/config.js";
import axios from "axios";
import "../styles/create.css";
import VideoPostForm from "../components/videopostform.js";
import TextPostForm from "../components/textpostform.js";
import aclesia_logo from "../images/aclesia-logo.png";
import churchicon from "../images/church.svg";
import create_icon from "../images/create.svg";
import Spinner from "../components/spinner";

import { encryptText, decryptText } from "../components/crypto.js";

// Separate Video and Text forms for clearer code

// The current Create function imports two functions, VideoPostForm and TextPostForm to
// create axios post request to upload content.

export default function Create() {
    const navigate = useNavigate();
    const [postType, setPostType] = useState("text"); // Default to text post

    const storedProfileStr = localStorage.getItem("userProfile"); // Get the stored data

    let storedProfile;
    try {
        // Attempt to decrypt and parse the stored data
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        // Handle decryption or parsing errors
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    const username = storedProfile?.username;

    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            navigate("/login"); // Redirect to login if no token is found
        } else {
            fetchProfile();
        }
    }, [username]); // Dependency on username to refetch if it changes

    const fetchProfile = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}profile/${storedProfile.id}`,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setProfile(response.data);
        } catch (error) {
            console.error("Failed to fetch profile", error);
            // Handle errors appropriately
            if (error.response && error.response.status === 404) {
                navigate("/not-found"); // Redirect to a not-found page or display a message
            }
        }
    };
    if (!profile) {
        return (
            <>
                <div className="loading-screen"></div>
                <Spinner />
            </>
        );
    }

    const url_tag = profile.user_type === "user" ? "user" : "church";

    return (
        <>
            <Navbar className="navbar-responsive" />
            <LeftBar className="leftbar-responsive" />
            <header className="responsive-header">
                <ul className="responsive-header-ul">
                    <li className="responsive-header-item">
                        <img id="responsive-header-img" src={aclesia_logo} />
                    </li>
                    <li className="responsive-header-item">
                        <p id="responsive-header-text">ACLESIA</p>
                    </li>
                </ul>
            </header>

            <div className="create-container">
                <div className="video-post-container">
                    <div className="button-container">
                        <button
                            className="btn"
                            onClick={() => setPostType("text")}
                        >
                            Post a Post
                        </button>
                        <button
                            className="btn"
                            onClick={() => setPostType("video")}
                        >
                            Post a Video
                        </button>

                        {postType === "video" ? (
                            <VideoPostForm />
                        ) : (
                            <TextPostForm />
                        )}
                    </div>
                </div>
            </div>

            <footer className="bottom-navbar-small">
                <ul className="bottom-nav-ul">
                    <a href="/churches" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-church" src={churchicon} />
                        </li>
                    </a>
                    <a href="/create" className="bottom-nav-item">
                        <li>
                            <img id="bottom-nav-create" src={create_icon} />
                        </li>
                    </a>
                    <a
                        href={`/${url_tag}/${username}`}
                        className="bottom-nav-item"
                    >
                        <li>
                            <img
                                width="50"
                                height="50"
                                id="bottom-nav-profile"
                                src={`${
                                    profile.profile_img.startsWith("/https:/")
                                        ? profile.profile_img.replace(
                                              "/https:/",
                                              "https://"
                                          )
                                        : profile.profile_img
                                }`}
                                alt="Profile"
                            />
                        </li>
                    </a>
                </ul>
            </footer>
        </>
    );
}
