import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/config";
import Navbar from "../components/navbar";
import LeftBar from "../components/leftbar";
import BackHome from "../components/backhome";
import "../styles/watch.css";
import { useParams } from "react-router-dom";
import { decryptText } from "../components/crypto.js";
import aclesia_logo from "../images/aclesia-logo.png";
import "../App.css";
import { IoPlayCircle } from "react-icons/io5";
import heart from "../images/heart.svg";
import VideoPlayers from "../components/VideoPlayer.js";

export default function Watch() {
    const { video_id } = useParams();
    const [details, setDetails] = useState(null);
    const [recommendedVideos, setRecommendedVideos] = useState([]);
    const [profiles, setProfiles] = useState({});
    const [userProfile, setUserProfile] = useState(null);
    const [followed_data, setFollowedData] = useState([]);
    const [followed_user_data, setFollowedUserData] = useState([]);

    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
        throw new Error(
            "No auth token found. User might need to log in again."
        );
    }

    const storedProfileStr = localStorage.getItem("userProfile");
    let storedProfile;
    try {
        storedProfile = JSON.parse(decryptText(storedProfileStr));
    } catch (error) {
        console.error("Error decrypting or parsing stored profile:", error);
        storedProfile = null;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [videoRes, recommendedRes] = await Promise.all([
                    axios.get(`${API_BASE_URL}video/${video_id}`, {
                        headers: {
                            Authorization: `JWT ${authToken}`,
                        },
                    }),
                    axios.get(`${API_BASE_URL}video/`, {
                        headers: {
                            Authorization: `JWT ${authToken}`,
                        },
                    }),
                ]);

                setDetails(videoRes.data);

                if (videoRes.data.user) {
                    fetchUserProfile(videoRes.data.user);
                }

                setRecommendedVideos(recommendedRes.data);

                recommendedRes.data.forEach((video) => {
                    if (video.user) {
                        fetchProfileData(video.user);
                    }
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [video_id, authToken]);

    useEffect(() => {
        if (storedProfile && userProfile?.id) {
            fetchFollowedData(storedProfile.id, userProfile.id);
            fetchFollowedUserData();
        }
    }, [storedProfile?.id, userProfile?.id]);

    const handleFollowToggle = async () => {
        try {
            // Disable the button immediately
            document.getElementById("following-btn").disabled = true;

            const isFollowing =
                followed_data?.length > 0 &&
                followed_data[0].followed === userProfile.id;

            const config = {
                headers: {
                    Authorization: `JWT ${authToken}`,
                },
            };

            if (isFollowing) {
                // Optimistically update the UI
                setFollowedData([]);
                // Send the delete request
                await axios.delete(
                    `${API_BASE_URL}followed/${userProfile.id}/${storedProfile.id}`,
                    config
                );
            } else {
                // Optimistically update the UI
                setFollowedData([{ followed: userProfile.id }]);
                // Send the post request
                await axios.post(
                    `${API_BASE_URL}follower/`,
                    {
                        user: storedProfile.id,
                        profile: userProfile.id,
                        followed: userProfile.id,
                    },
                    config
                );
            }

            // Fetch the followed data again to update the state
            fetchFollowedData(storedProfile.id, userProfile.id);
        } catch (error) {
            console.error("Error toggling follow status:", error);
        } finally {
            // Enable the button after the request is processed
            document.getElementById("following-btn").disabled = false;
        }
    };

    const fetchFollowedData = async (storedProfileId, userProfileId) => {
        if (!userProfileId || !storedProfileId) {
            console.error("User profile or stored profile is undefined.");
            return;
        }

        try {
            const response = await axios.get(
                `${API_BASE_URL}followed/${userProfileId}/${storedProfileId}`,
                {
                    headers: {
                        Authorization: `JWT ${authToken}`,
                    },
                }
            );
            setFollowedData(response.data);
        } catch (error) {
            console.error("Error fetching followed data:", error);
        }
    };

    const fetchUserProfile = (id) => {
        axios
            .get(`${API_BASE_URL}profile/${id}`, {
                headers: {
                    Authorization: `JWT ${authToken}`,
                },
            })
            .then((res) => {
                setUserProfile(res.data);
            })
            .catch((err) => {
                console.error(
                    `Error fetching profile data for user ID ${id}:`,
                    err
                );
            });
    };

    const fetchFollowedUserData = async () => {
        if (!userProfile) return;

        try {
            const response = await axios.get(
                `${API_BASE_URL}followeduser/${userProfile.id}`,
                {
                    headers: {
                        Authorization: `JWT ${authToken}`,
                    },
                }
            );
            setFollowedUserData(response.data);
        } catch (error) {
            console.error("Error fetching followed data:", error);
        }
    };

    const fetchProfileData = (id) => {
        if (!profiles[id]) {
            axios
                .get(`${API_BASE_URL}profile/${id}`, {
                    headers: {
                        Authorization: `JWT ${authToken}`,
                    },
                })
                .then((res) => {
                    setProfiles((prev) => ({ ...prev, [id]: res.data }));
                })
                .catch((err) => {
                    console.error(
                        `Error fetching profile data for user ID ${id}:`,
                        err
                    );
                });
        }
    };

    const url_tag = userProfile?.user_type === "user" ? "user" : "church";
    const subscriberCount = followed_user_data
        ? followed_user_data.filter(
              (follow) => follow.followed === userProfile.id
          ).length
        : 0;

    return (
        <>
            <Navbar />
            <LeftBar />
            <header className="responsive-header">
                <ul className="responsive-header-ul">
                    <li className="responsive-header-item">
                        <img
                            id="responsive-header-img"
                            src={aclesia_logo}
                            alt="Aclesia Logo"
                        />
                    </li>
                    <li className="responsive-header-item">
                        <p id="responsive-header-text">ACLESIA</p>
                    </li>
                </ul>
            </header>
            <div className="user-profile-container-watch">
                <BackHome />
                {details && (
                    <div>
                        <VideoPlayers
                            id="watch-video"
                            video={`${
                                details.video_file.startsWith("/https:/")
                                    ? details.video_file.replace(
                                          "/https:/",
                                          "https://"
                                      )
                                    : details.video_file
                            }`}
                        />

                        <h1 id="video-watch-title">{details.title}</h1>
                        {userProfile && (
                            <div className="other-user-info">
                                <ul className="other-user-info-ul">
                                    <div className="other-user-info-ul-1">
                                        <a
                                            className="other-user-profile"
                                            href={`/${url_tag}/${userProfile.username}`}
                                        >
                                            <div className="watch-profile-div">
                                                <li className="other-user-info-items">
                                                    <img
                                                        id="watch-profile-img"
                                                        src={`${
                                                            userProfile.profile_img.startsWith(
                                                                "/https:/"
                                                            )
                                                                ? userProfile.profile_img.replace(
                                                                      "/https:/",
                                                                      "https://"
                                                                  )
                                                                : userProfile.profile_img
                                                        }`}
                                                        alt="profile"
                                                    />
                                                </li>
                                                <li className="other-user-info-items">
                                                    <ul className="other-user-info-items-ul">
                                                        <li className="other-user-info-items-ul-items">
                                                            <p>
                                                                {
                                                                    userProfile.username
                                                                }
                                                            </p>
                                                        </li>
                                                        <li className="other-user-info-items-ul-items-followers">
                                                            <p>
                                                                {
                                                                    subscriberCount
                                                                }{" "}
                                                                Subscribers
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </div>
                                        </a>
                                        {storedProfile.username !==
                                            userProfile.username && (
                                            <li className="other-user-info-items">
                                                <button
                                                    id="following-btn"
                                                    onClick={handleFollowToggle}
                                                >
                                                    {followed_data?.length >
                                                        0 &&
                                                    followed_data[0]
                                                        .followed ===
                                                        userProfile.id
                                                        ? "Subscribed"
                                                        : "Subscribe"}
                                                </button>
                                            </li>
                                        )}
                                    </div>
                                    <div className="other-user-info-ul-2">
                                        {/* Additional buttons like comments, likes, shares can be added here */}
                                    </div>
                                </ul>
                            </div>
                        )}
                        <div className="video-description">
                            <ul className="video-description-ul">
                                <li className="video-description-ul-items">
                                    <p className="description-txt">
                                        {details.description}
                                    </p>
                                </li>
                                {/* <li className="video-description-ul-items">
                  <p className="category">Category: {details.category}</p>
                </li> */}
                            </ul>
                        </div>
                    </div>
                )}
                <div className="divider-container-watch">
                    <div className="divider-watch"></div>
                </div>
                <div className="break-txt-div">
                    <ul className="break-txt-ul">
                        <li className="berak-txt-item">
                            <img
                                id="break-txt-icon"
                                src={heart}
                                alt="Heart Icon"
                            />
                        </li>
                        <li className="berak-txt-item">
                            <p id="break-txt">You might also Like</p>
                        </li>
                    </ul>
                </div>
                <div className="video-items-watch">
                    {recommendedVideos.map((video, id) => (
                        <div className="video-div-churches" key={id}>
                            <a id="video-link-item" href={`/watch/${video.id}`}>
                                <img
                                    id="video-thumbail"
                                    src={`${
                                        video.thumbnail.startsWith("/https:/")
                                            ? video.thumbnail.replace(
                                                  "/https:/",
                                                  "https://"
                                              )
                                            : video.thumbnail
                                    }`}
                                    alt="video thumbnail"
                                />

                                {video.user && profiles[video.user] && (
                                    <div className="other-user-info">
                                        <img
                                            id="user-profile-img-churches"
                                            src={`${
                                                profiles[
                                                    video.user
                                                ]?.profile_img.startsWith(
                                                    "/https:/"
                                                )
                                                    ? profiles[
                                                          video.user
                                                      ]?.profile_img.replace(
                                                          "/https:/",
                                                          "https://"
                                                      )
                                                    : profiles[video.user]
                                                          ?.profile_img
                                            }`}
                                            alt="profile"
                                        />

                                        <p id="user-profile-txt-churches">
                                            {profiles[video.user].username}
                                        </p>
                                    </div>
                                )}
                                <h1 id="video-title-watch">{video.title}</h1>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
