import "../styles/create.css";
import upload_icon from "../images/upload-cloud-icon.png";
import image from "../images/image.svg";
import { API_BASE_URL } from "../components/config.js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useCSRFToken from "../components/usecsrftoken.js";
import { encryptText, decryptText } from "../components/crypto.js";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

const CHUNK_SIZE = 50 * 1024 * 1024; // 50MB
const MAX_CONCURRENT_UPLOADS = 8; // Number of concurrent uploads

export default function VideoPostForm() {
  const csrfToken = useCSRFToken();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoFileName, setVideoFileName] = useState("");
  const [thumbnailFileName, setThumbnailFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const storedProfileStr = localStorage.getItem("userProfile");
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  let storedProfile;
  try {
    storedProfile = JSON.parse(decryptText(storedProfileStr));
  } catch (error) {
    console.error("Error decrypting or parsing stored profile:", error);
    storedProfile = null;
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}profile/${storedProfile.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setProfile(response.data);
    } catch (error) {
      console.error("Failed to fetch profile", error);
      if (error.response && error.response.status === 404) {
        navigate("/not-found");
      }
    }
  };

  const uploadChunk = async (chunk, chunkIndex, totalChunks) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunk_index", chunkIndex);
    formData.append("total_chunks", totalChunks);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("user", storedProfile.id);
    formData.append("profile", storedProfile.id);
    formData.append("thumbnail", thumbnail);

    // Log chunk upload start
    console.log(`Uploading chunk ${chunkIndex + 1} of ${totalChunks}`);

    return axios.post(`${API_BASE_URL}video/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        "X-CSRFToken": csrfToken,
        Authorization: `JWT ${localStorage.getItem("authToken")}`,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(
          `Progress for chunk ${chunkIndex + 1}: ${progress}%`
        );
      },
    });
  };

  const handleUpload = async () => {
    if (!videoFile || !storedProfile) {
      console.error("No video file or user profile available.");
      alert("Please log in again.");
      return;
    }

    setLoading(true);

    const totalChunks = Math.ceil(videoFile.size / CHUNK_SIZE);
    let completedChunks = 0;

    const updateProgress = () => {
      setUploadProgress((completedChunks / totalChunks) * 100);
      console.log(
        `Overall upload progress: ${(completedChunks / totalChunks) * 100}%`
      );
    };

    const chunkUploadPromises = [];

    for (let start = 0; start < videoFile.size; start += CHUNK_SIZE) {
      const chunk = videoFile.slice(start, start + CHUNK_SIZE);
      const chunkIndex = start / CHUNK_SIZE;

      chunkUploadPromises.push(
        uploadChunk(chunk, chunkIndex, totalChunks)
          .then(() => {
            completedChunks++;
            updateProgress();
            console.log(`Chunk ${chunkIndex + 1} of ${totalChunks} uploaded`);
          })
          .catch((error) => {
            console.error(`Error uploading chunk ${chunkIndex + 1}`, error);
          })
      );

      if (chunkUploadPromises.length >= MAX_CONCURRENT_UPLOADS) {
        await Promise.all(chunkUploadPromises);
        chunkUploadPromises.length = 0; // Clear the array of promises
      }
    }

    await Promise.all(chunkUploadPromises); // Ensure remaining chunks are uploaded

    setLoading(false);
    alert("Video uploaded successfully!");
    fetchProfile();
    navigate(`/${profile.user_type}/${storedProfile.username}`);
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <Spinner />
        <div className="loading-bar-container">
          <div className="loading-bar" style={{ width: `${uploadProgress}%` }}></div>
        </div>
        <div className="loading-percentage">{uploadProgress}%</div>
      </div>
    );
  }

  return (
    <div className="video-submit-container">
      <input
        id="video-content"
        name="video_file"
        type="file"
        onChange={(e) => {
          setVideoFile(e.target.files[0]);
          setVideoFileName(e.target.files[0].name);
        }}
        required
        hidden
      />
      <label id="video-content-id" htmlFor="video-content">
        <div className="post-container-image">
          <p className="upload-text">Click to Upload Video</p>
          <img className="cloud-icon" src={upload_icon} alt="Upload" />
        </div>
      </label>
      {videoFileName && <p id="selected-video">Selected Video: {videoFileName}</p>}

      <input
        id="thumbnail-content"
        name="thumbnail_file"
        type="file"
        onChange={(e) => {
          setThumbnail(e.target.files[0]);
          setThumbnailFileName(e.target.files[0].name);
        }}
        required
        hidden
      />
      <label htmlFor="thumbnail-content">
        <div className="thumbnail-container-image">
          <p className="thumbnail-text">Click to Upload Thumbnail</p>
          <img className="upload-icon" src={image} alt="Upload Thumbnail" />
        </div>
      </label>
      {thumbnailFileName && <p id="selected-thumbnail">Selected Thumbnail: {thumbnailFileName}</p>}

      <div className="create-input-container">
        <label id="label-title" htmlFor="title-content">
          Title
        </label>
        <input
          id="title-content"
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <label id="label-description" htmlFor="description-content">
          Description
        </label>
        <input
          id="description-content"
          name="description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <button id="handle-upload-btn" onClick={handleUpload}>
          Upload Video
        </button>
      </div>
    </div>
  );
}
