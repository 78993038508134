
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './pages/home'
import Login from './pages/login'
import Register from './pages/register'
import ActivationPage from './pages/activation'
import DeleteUser from './pages/delete'
import Create from './pages/create'
import UserProfile from './pages/userprofile'
import ChurchProfile from './pages/userprofile'
import Edit from './pages/edit'
import Churches from './pages/churches'
import Watch from './pages/watch'
import Forgot from './pages/forgotpassword'

function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Churches />}/>
          <Route path="/home" element={<Home/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/create" element={<Create/>} />
          <Route path="/churches" element={<Churches/>} />
          <Route path="/user/:username" element={<UserProfile/>} />
          <Route path="/church/:username" element={<ChurchProfile/>} />
          <Route path="/activate/:uid/:token" element={<ActivationPage/>} />
          <Route path="/delete" element={<DeleteUser/>} />
          <Route path="/watch/:video_id" element={<Watch/>} />
          <Route path="/edit/:username" element={<Edit/>} />
          <Route path="/edit/:username" element={<Edit/>} />
          <Route path="/password/reset/confirm/:uid/:token" element={<Forgot/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
